import { j as a } from "./jsx-runtime-B6kdoens.js";
import { I as S } from "./Icon-AQuIivEZ.js";
import { S as i } from "./Stack-C1p7gIIp.js";
import { T as s } from "./Text-RuGJaJiA.js";
import { c as N } from "./utils-CJ9afRe1.js";
var t = /* @__PURE__ */ ((e) => (e.NOT_SCANNED = "NOT_SCANNED", e.NOT_STAGED = "NOT_STAGED", e.NOT_CHECKED = "NOT_CHECKED", e.CLEAN = "CLEAN", e.INFECTED = "INFECTED", e))(t || {});
const E = {
  [t.CLEAN]: "Clean",
  [t.INFECTED]: "Infected",
  [t.NOT_SCANNED]: "Scanning",
  [t.NOT_STAGED]: "Scanning",
  [t.NOT_CHECKED]: "Scanning",
  undefined: "Clean",
  null: "Clean"
}, u = (e) => {
  const n = e.virusScanState === t.CLEAN || !e.virusScanState;
  return /* @__PURE__ */ a.jsxs(
    i,
    {
      row: !0,
      className: N("rounded-lg px-2 py-.5", {
        "bg-yellow-30": e.virusScanState === t.NOT_SCANNED || e.virusScanState === t.NOT_STAGED,
        "bg-red-30": e.virusScanState === t.INFECTED,
        "bg-green-20": n
      }),
      space: "xs",
      items: "center",
      children: [
        /* @__PURE__ */ a.jsx(
          S,
          {
            name: "VirusShield",
            className: N({
              "text-yellow-70": e.virusScanState === t.NOT_SCANNED || e.virusScanState === t.NOT_STAGED,
              "text-destructive": e.virusScanState === t.INFECTED,
              "text-green-200": n
            }),
            strokeWidth: 2,
            width: 12,
            height: 12
          }
        ),
        /* @__PURE__ */ a.jsx(
          s,
          {
            size: "xs",
            className: N({
              "text-yellow-70": e.virusScanState === t.NOT_SCANNED || e.virusScanState === t.NOT_STAGED,
              "text-destructive": e.virusScanState === t.INFECTED,
              "text-green-200": n
            }),
            children: E[e.virusScanState]
          }
        ),
        (e.virusScanState === t.NOT_SCANNED || e.virusScanState === t.NOT_STAGED) && /* @__PURE__ */ a.jsx(
          S,
          {
            name: "Refresh",
            className: "text-yellow-70 animate-spin [&_path:last-child]:hidden",
            strokeWidth: 2,
            width: 12,
            height: 12
          }
        )
      ]
    }
  );
};
export {
  u as default
};
