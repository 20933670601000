import { j as d } from "./jsx-runtime-B6kdoens.js";
import { I as s } from "./Icon-AQuIivEZ.js";
const i = {
  Folder: [],
  DocuSign: [],
  Form: ["form"],
  None: [],
  Audio: [
    // list all possible audio files extensions eg: mp3, wav, ogg, etc
    "mp3",
    "wav",
    "ogg",
    "wma",
    "flac",
    "aac",
    "aiff",
    "alac",
    "dsd",
    "pcm",
    "ape",
    "m4a"
  ],
  Video: [
    // list all possible video files extensions eg: mp4, avi, etc
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "m4v",
    "mpeg",
    "mpg",
    "3gp"
  ],
  Photo: [
    // list all possible photo files extensions eg: png, jpg, etc
    "png",
    "jpg",
    "jpeg",
    "gif",
    "bmp",
    "svg",
    "webp",
    "tiff",
    "psd",
    "raw",
    "heic"
  ],
  Pdf: ["pdf"],
  Text: ["txt"],
  PowerPoint: [
    // list all possible slide files extensions eg: ppt, pptx, etc
    "ppt",
    "pptx",
    "pps",
    "ppsx",
    "pot",
    "potx",
    "odp",
    "key"
  ],
  Document: [
    // list all possible document files extensions eg: doc, docx, etc
    "doc",
    "docx",
    "dot",
    "dotx",
    "odt",
    "rtf",
    "txt",
    "wpd",
    "wps",
    "pages"
  ],
  SelfSign: ["sign"],
  Excel: [
    // list all possible excel files extensions eg: xls, xlsx, etc
    "xls",
    "xlsx",
    "xlsm",
    "xlsb",
    "xlt",
    "xltx",
    "xltm",
    "ods",
    "numbers"
  ],
  Zip: ["zip"]
}, n = {
  Pdf: {
    name: "Pdf",
    width: 20,
    height: 24
  },
  None: {
    name: "DocumentUpload",
    width: 20,
    height: 24
  },
  Folder: {
    name: "ColorfulFolder",
    width: 20,
    height: 16
  },
  DocuSign: {
    name: "Docusign",
    width: 20,
    height: 20
  },
  SelfSign: {
    name: "SelfSign",
    width: 20,
    height: 20
  },
  Zip: {
    name: "Zip",
    width: 20,
    height: 26
  },
  Form: {
    name: "FillableForm",
    width: 17,
    height: 21
  },
  Excel: {
    name: "Excel",
    width: 20,
    height: 24
  },
  Video: {
    name: "Video",
    width: 20,
    height: 20
  },
  Text: {
    name: "MessageText",
    width: 20,
    height: 20
  },
  Audio: {
    name: "Audio",
    width: 20,
    height: 20
  },
  Photo: {
    name: "PhotosAndImages",
    width: 20,
    height: 26
  },
  Slide: {
    name: "Slides",
    width: 20,
    height: 20
  },
  PowerPoint: {
    name: "PowerPoint",
    width: 20,
    height: 20
  },
  Document: {
    name: "Docx",
    width: 20,
    height: 25
  }
}, m = (t) => {
  if (!t)
    return "";
  const e = t.split("?")[0].split(".").pop();
  return e ? e.toLowerCase() : "doc";
}, l = (t) => Object.keys(i).find((e) => i[e].includes(t)), a = (t) => {
  const e = Object.keys(i).find((o) => i[o].includes(t));
  return e ? n[e] : n.None;
}, p = (t) => {
  const e = a(m(t.fileName)), o = t.size === "default" ? e.width : e.width / 1.25, h = t.size === "default" ? e.height : e.height / 1.25;
  return /* @__PURE__ */ d.jsx(
    s,
    {
      name: e.name,
      width: o,
      height: h,
      className: t.className
    }
  );
};
export {
  p as F,
  l as a,
  m as g
};
