import { j as r } from "./jsx-runtime-B6kdoens.js";
import "./Accordion-1hoRro1D.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-ChOUYHf0.js";
import "./DropdownMenu-BFZ8b7Ue.js";
import "./Icon-AQuIivEZ.js";
import "./Input-1P9Hc4eP.js";
import "./Label-CI8_Vg74.js";
import { T as i } from "./Text-RuGJaJiA.js";
import "./Tooltip-Dy7rqQaX.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-BftObVji.js";
import "./Toast-CJ18lVDL.js";
import "react";
import "./Toggle-Cn0qt44_.js";
const v = (t) => /* @__PURE__ */ r.jsxs(
  "div",
  {
    className: e("w-full max-w-fit rounded-md bg-white p-6 gap-4 flex flex-col items-center justify-center", t.className),
    children: [
      /* @__PURE__ */ r.jsx(i, { children: t.title }),
      /* @__PURE__ */ r.jsx("div", { className: "w-6 h-6 aspect-square rounded-full z-0 border-2 border-blue-100 border-t-transparent animate-spin" }),
      /* @__PURE__ */ r.jsx(i, { variant: "secondary", size: "xs", children: t.hint })
    ]
  }
);
export {
  v as L
};
