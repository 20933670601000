import { j as s } from "./jsx-runtime-B6kdoens.js";
import { $ as c, a as p, b as n, c as f, d as x, e as b, f as k, g as D } from "./index-D9Xim34e.js";
import * as l from "react";
import { c as o } from "./utils-CJ9afRe1.js";
const T = D, $ = c;
console.log("Trigger.displayName", c.displayName);
$.displayName = c.displayName;
const r = ({ ...a }) => /* @__PURE__ */ s.jsx(p, { ...a });
r.displayName = p.displayName;
const g = l.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  n,
  {
    ref: t,
    className: o(
      "fixed inset-0 z-dialog bg-black-primary/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...e
  }
));
g.displayName = n.displayName;
const y = l.forwardRef(({ className: a, children: e, overlayClassName: t, ...R }, h) => /* @__PURE__ */ s.jsx(r, { children: /* @__PURE__ */ s.jsx(g, { className: t, onWheel: (d) => d.stopPropagation(), children: /* @__PURE__ */ s.jsx(
  f,
  {
    onInteractOutside: (d) => {
      var i, m;
      (((i = d.target) == null ? void 0 : i.closest("[role='alert']")) ?? ((m = d.target) == null ? void 0 : m.closest("[data-toast='true']"))) && d.preventDefault();
    },
    ref: h,
    className: o(
      "fixed sm:max-h-[95%] left-[50%] top-[50%] z-dialog flex flex-col w-full sm:max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
      a
    ),
    ...R,
    children: e
  }
) }) }));
y.displayName = f.displayName;
const N = ({
  className: a,
  ...e
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col py-3 px-4 text-center sm:text-left",
      a
    ),
    ...e
  }
);
N.displayName = "Header";
const u = ({
  className: a,
  hideDivider: e,
  ...t
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 px-3 py-2",
      {
        "border-t": !e
      },
      a
    ),
    ...t
  }
);
u.displayName = "Footer";
const j = l.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  x,
  {
    ref: t,
    className: o(
      "text-black- py-2 px-3.5 text-sm font-medium leading-none tracking-tight",
      a
    ),
    ...e
  }
));
j.displayName = x.displayName;
const w = l.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  b,
  {
    ref: t,
    className: o("px-3", a),
    ...e
  }
));
w.displayName = b.displayName;
const C = Object.assign(T, {
  Content: y,
  Description: w,
  Footer: u,
  Header: N,
  Title: j,
  Trigger: $,
  Portal: r,
  Close: k
});
export {
  C as D
};
